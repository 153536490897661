import { forwardRef } from 'react';
import { Icon, IconProps } from '@iconify/react';
import Box, { BoxProps } from '@mui/material/Box';

export type IconifyProps = BoxProps & IconProps;

export const Iconify = forwardRef<SVGElement, IconifyProps>(({ width = 20, sx, ...other }, ref) => (
  <Box
    ref={ref}
    component={Icon}
    sx={{
      width,
      height: width,
      flexShrink: 0,
      display: 'inline-flex',
      ...sx,
    }}
    {...other}
  />
));

Iconify.displayName = 'Iconify';

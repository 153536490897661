import { createContext, ReactNode, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { useRouter } from 'next/router';
import { defineAbility } from '@casl/ability';
import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  verifyPasswordResetCode,
} from 'firebase/auth';
import { collection, doc, setDoc } from 'firebase/firestore';
import { noop } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useAcquireTokenMutation, useMyAccountLazyQuery, useReleaseTokenMutation, UserRole } from 'src/api';
import { PATH_AUTH } from 'src/paths';
import { apolloClient } from 'src/utils/apollo-client';
import { AuthAction, AuthState, FirebaseType, UserAbility } from './AuthTypes';
import { AUTH, DB } from './Firebase';

const initialState: AuthState = {
  isInitialized: false,
  isAuthenticated: false,
  isValidated: false,
  isAdmin: false,
  user: null,
};

function reducer(state: AuthState, action: AuthAction) {
  switch (action.type) {
    case 'LOGIN': // we have a session
      return {
        ...state,
        ...action.payload,
        isInitialized: true,
        isAuthenticated: true,
      };

    case 'VALIDATE': // we have a valid session (no other logins for this account)
      return {
        ...state,
        ...action.payload,
        isValidated: true,
      };

    case 'LOGOUT':
      return {
        ...initialState,
        isInitialized: true,
      };

    default:
      return state;
  }
}

const AuthContext = createContext<FirebaseType | null>(null);

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
}

// sync type with backend
export interface TokenClaims {
  admin: boolean;
  roles: ClaimRole[];
  aIds: string[];
  _id: string;
}

export type ClaimRole = 'aa' | 'aar' | 'u' | 'res' | 'ro' | 'wf' | 'ac';

export function claimToRole(role: ClaimRole) {
  switch (role) {
    case 'aa':
      return UserRole.AccountAdmin;
    case 'aar':
      return UserRole.AccountAdminRestricted;
    case 'u':
      return UserRole.User;
    case 'res':
      return UserRole.Restricted;
    case 'ro':
      return UserRole.Readonly;
    case 'wf':
      return UserRole.Workflow;
    case 'ac':
      return UserRole.Accounting;
    default:
      throw 'Invalid role';
  }
}

// sync type with backend

export function AuthProvider({ children }: { children: ReactNode }) {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const [acquireLoginToken] = useAcquireTokenMutation();
  const [releaseLoginToken] = useReleaseTokenMutation();
  const [myAccountLazyQuery] = useMyAccountLazyQuery();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [accountId, setAccountId] = useState(() =>
    typeof localStorage !== 'undefined' ? localStorage.getItem('accountId') : '',
  );

  useEffect(() => {
    return onAuthStateChanged(AUTH, async (user) => {
      if (!user) {
        return dispatch({ type: 'LOGOUT' });
      }

      const token = await user.getIdTokenResult();
      const claims = token.claims as unknown as TokenClaims;
      let accountIndex = claims.aIds.findIndex((id) => id === accountId);
      const isAdmin = !!claims.admin;

      // check if we either have an outdated user or no user at all
      if (accountIndex === -1 || !accountId || accountId === 'undefined') {
        accountIndex = 0;
        const newAccountId = claims.aIds[accountIndex] as string;
        localStorage.setItem('accountId', newAccountId);
        setAccountId(newAccountId);
      }

      dispatch({ type: 'LOGIN', payload: { isAdmin, user } });

      // NOTE: use this line to quickly test a user role in the UI
      // const role: ClaimRole = 'u';
      const role = claims.roles[accountIndex] as ClaimRole;

      const myAccountResponse = await myAccountLazyQuery();

      if (myAccountResponse.error) {
        enqueueSnackbar('Fehler beim Login. Bitte beim Support melden.', { variant: 'error' });
      }

      const myAccount = myAccountResponse.data?.myAccount;

      dispatch({
        type: 'VALIDATE',
        payload: {
          ability: getUserAbility(
            role,
            myAccount?.invoicing.byAccountingOnly,
            myAccount?.customSettings.disablePartnerManagement,
          ),
        },
      });
    });
  }, [dispatch, accountId, setAccountId, myAccountLazyQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  const verifyResetCode = (code: string) => verifyPasswordResetCode(AUTH, code);
  const confirmReset = (code: string, password: string) => confirmPasswordReset(AUTH, code, password);

  const login = useCallback(
    async (email: string, password: string) => {
      const userCredentials = await signInWithEmailAndPassword(AUTH, email, password);
      const { data } = await acquireLoginToken();
      localStorage.setItem('loginToken', data?.acquireLoginToken ?? '');
      return userCredentials;
    },
    [acquireLoginToken],
  );

  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const res = await createUserWithEmailAndPassword(AUTH, email, password);
      const uid = res.user?.uid;

      const userRef = doc(collection(DB, 'users'), uid);

      await setDoc(userRef, {
        uid,
        email,
        displayName: `${firstName} ${lastName}`,
      });
    },
    [],
  );

  const logout = useCallback(async () => {
    await releaseLoginToken().catch(noop);
    await signOut(AUTH);
    apolloClient.resetStore();
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('accountId', accountId as string);
    router.replace(PATH_AUTH.login);
  }, [accountId, releaseLoginToken, router]);

  const switchAccount = useCallback((newAccountId: string) => {
    localStorage.setItem('accountId', newAccountId);
    document.body.style.pointerEvents = 'none';
    document.body.animate([{ filter: 'grayscale(1)' }], { duration: 1000, easing: 'ease-out' });
    window.location.reload();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        accountId,
        login,
        register,
        logout,
        switchAccount,
        verifyResetCode,
        confirmReset,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const getUserAbility = (role: ClaimRole, byAccountingOnly = false, disablePartnerManagement = false) =>
  defineAbility<UserAbility>((allow, forbid) => {
    switch (claimToRole(role)) {
      case UserRole.AccountAdmin:
        allow('manage', 'all');
        if (byAccountingOnly) {
          forbid('manage', 'invoice');
        }
        allow('read', 'all');
        break;

      case UserRole.AccountAdminRestricted:
        allow('manage', 'all');
        forbid('manage', 'inventory');
        forbid('manage', 'bonus');
        if (byAccountingOnly) {
          forbid('manage', 'invoice');
        }
        allow('read', 'all');
        break;

      case UserRole.User:
        allow('manage', 'all');
        forbid('manage', 'account');
        if (byAccountingOnly) {
          forbid('manage', 'invoice');
        }
        allow('read', 'all');
        break;

      case UserRole.Accounting:
        allow('manage', 'invoice');
        allow('read', 'all');
        break;

      case UserRole.Workflow:
        allow('manage', 'task');
        allow('read', 'all');
        break;

      case UserRole.Restricted:
        allow('manage', 'all');
        forbid('manage', 'account');
        forbid('manage', 'inventory');
        forbid('manage', 'bonus');
        forbid('manage', 'bundle');
        forbid('manage', 'invoice');
        allow('read', 'all');
        break;

      case UserRole.Readonly:
        allow('read', 'all');
        break;
    }

    if (disablePartnerManagement) {
      forbid('manage', 'partner');
      allow('read', 'partner');
    }
  });

import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { ConfirmationDialog, ConfirmOptions } from './ConfirmationDialog';

const DEFAULT_OPTIONS: ConfirmOptions = {
  title: 'Sind Sie sicher?',
  content: null,
  confirmationText: 'Bestätigen',
  cancellationText: 'Abbrechen',
  dialogProps: {},
  dialogActionsProps: {},
  confirmationButtonProps: {},
  cancellationButtonProps: {},
  titleProps: {},
  contentProps: {},
  allowClose: true,
  hideCancelButton: false,
};

type ConfirmFn = (options?: ConfirmOptions) => Promise<boolean>;
type ResolveFunction = (value: boolean | PromiseLike<boolean>) => void;

const ConfirmContext = createContext<ConfirmFn>(() => Promise.resolve(false));

export const useConfirm = () => useContext(ConfirmContext);

export function ConfirmProvider({ children }: { children: ReactNode }) {
  const [options, setOptions] = useState({});
  const [resolveFn, setResolveFn] = useState<ResolveFunction | null>(null);

  const confirm = useCallback<ConfirmFn>((options?: ConfirmOptions) => {
    return new Promise<boolean>((resolve) => {
      setOptions({ ...DEFAULT_OPTIONS, ...options });
      setResolveFn(() => resolve); // NOTE: we cannot pass `resolve` directly as react treats it like a state setter function
    });
  }, []);

  const handleClose = useCallback(() => {
    setResolveFn(null);
  }, []);

  const handleCancel = useCallback(() => {
    resolveFn?.(false);
    handleClose();
  }, [resolveFn, handleClose]);

  const handleConfirm = useCallback(() => {
    resolveFn?.(true);
    handleClose();
  }, [resolveFn, handleClose]);

  return (
    <>
      <ConfirmContext.Provider value={confirm}>{children}</ConfirmContext.Provider>
      {resolveFn !== null && (
        <ConfirmationDialog
          open
          options={options}
          onClose={handleClose}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
}
